<template>
  <footer class="relative bg-darkgreen pt-8 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-darkgreen fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold text-white">Rezervace</h4>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            Telefon: <a href="tel:725 000 209">725 000 209</a>
          </h5>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            Email: <a href="mailto:info@navrat-ke-zdravi.cz">info@navrat-ke-zdravi.cz</a>
          </h5>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            Volejte od pondělí do pátku
          </h5>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            mezi 9 - 16 hodinou
          </h5>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold text-white">Provozovna</h4>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            Novodvorská 3060, Frýdek-Místek
          </h5>
          <h5 class="text-lg mt-0 mb-2 text-white-opacity-74">
            Zdravotní středisko, nad lékárnou Benu, 1.patro
          </h5>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
          id="kontakt"
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-white-opacity-74 font-semibold py-1">
            Copyright © {{ date }} provozovatel Jiří Havelka.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    date() {
      const beginningDate = 2021
      const currentDate = new Date().getFullYear()
      return currentDate === beginningDate ? beginningDate : `${beginningDate} - ${currentDate}`
    }
  }
};
</script>
<style>
.bg-darkgreen {
  background-color: #587B67;
}
.text-darkgreen {
  color: #587B67;
}
.text-white-opacity-74 {
  color: rgba(255, 255, 255, 0.74);
}
</style>
