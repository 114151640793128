<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
* {
  /* color: white; */
  font-family: Roboto-Regular;
  letter-spacing: 0.15px;
}
</style>
