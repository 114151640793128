<template>
  <div>
    <index-navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="headerStyle"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  Nabídka sportovních masáží -
                  pro rovnováhu těla i duše
                </h1>
<!--                <p class="mt-4 text-lg text-blueGray-200"></p>-->
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="upper-polygon fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-green -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Sportovní masáž celého těla</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Kombinace různých hmatů a intenzity působí na svalstvo, účinek je okamžitý a napomáhá kompletní regeneraci organismu.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                      class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Sportovní masáž zad a šíje</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Vhodná, pokud vás trápí chronická bolest svalů, zad
                    a šíje. Stejně tak, pokud máte sedavý styl života či hodně sportujete.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightgray"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Sportovní masáž rukou či nohou</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Rehabilitační účinky masáže, spočívají v kombinaci mechanických a reflexních hmatů, nabudí organismus regeneraci organismu.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32" id="nabidka">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Sportovní masáž
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
              >
                Díky šikovným rukám maséra je sportovní masáž provedena tak, aby se prohřálo a prokrvilo stuhlé svalstvo, organismus se poté mnohem lépe vyrovnává se zátěží, stresem a únavou.
              </p>


              <table class="table-fixed">
                <thead>
                <tr>
                  <th class="w-1/2">Typ</th>
                  <th class="w-1/4">Cena</th>
                  <th class="w-1/4">Čas</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Reflexní masáž</td>
                  <td>550 Kč</td>
                  <td>30 minut.</td>
                </tr>
                <tr>
                  <td>Celková masáž</td>
                  <td>1000 Kč</td>
                  <td>60 minut.</td>
                </tr>
                <tr>
                  <td>Reflexní + celková masáž</td>
                  <td>1500 Kč</td>
                  <td>90 minut.</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightgray"
              >
                <img
                  alt="..."
                  src=""
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-lightgray fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-black">
                    Automatická kresba
                  </h4>
                  <p class="text-md font-light mt-2 text-black">
                    Technika automatické kresby zaujala i mnoho umělců, mnohá abstraktní a surrealistická díla vznikla právě tímto způsobem. Současní léčitelé ji používají k diagnostice stavu zdraví nebo vytváření léčivých a harmonizačních obrázků. Setkáme se s ní i v psychiatrii jako s prostředkem k vyjádření nálad pacienta.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import FooterComponent from "@/components/Footer.vue";
import IndexNavbar from "@/components/Navbar.vue";

import header from "@/assets/img/P9051618.jpg";

export default {
  computed: {
    /**
     * @description Kameny v hlavičce
     * @returns {string}
     */
    headerStyle () {
      return `background-image: url('${header}');`
    }
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>

<style>
.bg-green {
  background-color: #FFFBEB; /* amber50 */
}
.bg-lightgray {
  /*background-color: #effaff;*/
  color: black; /* todo ? */
}
/* BARVA POLYGONU */
.text-lightgray {
  color: white;
}
.upper-polygon {
  color: #FFFBEB; /* amber50 */
}
</style>
